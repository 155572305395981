import { mapGetters } from 'vuex'
import { encrypt } from '../utils/crypto'
export default {
  data () {
    return {
      imagesTypes: ['image/bmp', 'image/png', 'image/jpeg', 'image/jpeg'],
      whoHasAccessList: [
        { id: 1, name: 'Anyone' },
        { id: 2, name: 'Only me' },
        { id: 3, name: 'Selected Users' }
      ]
    }
  },
  computed: {
    ...mapGetters(['systemDetails', 'userDetails']),
    $_requiredValidation () {
      return [val => !!val || 'Required', val => !/^\s*$/.test(val) || 'Required']
    },
    $_multiSelectValidation () {
      return [
        val => !!val || 'Required',
        val => (val && val.length > 0) || 'Required'
      ]
    },
    $_HourValidation () {
      return [
        val => !!val || 'Required',
        val => this.$formatter.replaceCommaWithDot(val) <= 24 || 'Invalid Hours',
        val => this.$formatter.replaceCommaWithDot(val) > 0 || 'Invalid Hours'
      ]
    },
    $_toInvoiceValidation () {
      return [
        // val => !!val || 'Required',
        val => this.$formatter.replaceCommaWithDot(val) <= 24 || 'Invalid Hours',
        val => this.$formatter.replaceCommaWithDot(val) >= 0 || 'Invalid Hours'
      ]
    },
    $_emailAndRequiredValidation () {
      return [
        (v) => !!(v) || 'Required',
        (v) => v ? /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/.test(v) || 'Invalid Email Address' : true
      ]
    },
    $_numberAndRequiredValidation () {
      return [
        (v) => !!(v) || 'Required',
        val => (val) ? /^[0-9.,]+$/.test(val) || 'Not Valid Number' : true,
        val => (val) ? !isNaN(this.$formatter.replaceCommaWithDot(val)) || 'Not Valid Number' : true
      ]
    },
    $_numberValidation () {
      return [
        val => (val) ? /^[0-9]+$/.test(val) || 'Not Valid Number' : true,
        val => (val) ? !isNaN(this.$formatter.replaceCommaWithDot(val)) || 'Not Valid Number' : true
      ]
    },
    $_norwegianNumberValidation () {
      return [
        val => (val) ? /^[0-9.,]+$/.test(val) || 'Not Valid Number' : true,
        val => (val) ? !isNaN(this.$formatter.replaceCommaWithDot(val)) || 'Not Valid Number' : true
      ]
    },
    $_norwegianNumberMinusValidation () {
      return [
        val => (val) ? /^-?[0-9.,]+$/.test(val) || 'Not Valid Number' : true,
        val => (val) ? !isNaN(this.$formatter.replaceCommaWithDot(val)) || 'Not Valid Number' : true
      ]
    },
    $_emailValidation () {
      return [
        (v) => v ? /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/.test(v) || 'Invalid Email Address' : true
      ]
    },
    $_discountValidation () {
      return [
        (val) => val ? (this.$formatter.replaceCommaWithDot(val) <= 100 && this.$formatter.replaceCommaWithDot(val) >= 0) || 'Invalid Number' : true
      ]
    },
    $_numberLength () {
      return [
        (val) => val ? (val && val.length <= 4) || this.$t('maxCharacters', { charCount: 4 }) : true
      ]
    },
    $_fieldNameValidation () {
      return [
        (v) => v ? /^[a-zA-Z0-9_]*$/.test(v) || 'Invalid name' : true
      ]
    },
    $_columnValidation () {
      return [
        v => !!v || 'Required',
        v => (v <= 12 && v >= 1) || 'Enter columns b/w 1 to 12'
      ]
    },
    $_passwordConfirmationRules () {
      return [
        (v) => !!v || 'Required',
        (v) => v === this.resetObj.password || 'Passwords are different'
      ]
    },
    $_accountType () {
      return [
        { text: 'Account', value: 'account' },
        { text: 'Lead', value: 'lead' },
        { text: 'Customer', value: 'customer' },
        { text: 'Supplier', value: 'supplier' }
      ]
    },
    inventCompItem () {
      return ['OK', 'Def', 'R', 'J', 'S', 'E']
    },
    $_invoiceValidation () {
      return [
        val => (val) ? /^[0-9.,]+$/.test(val) || this.$t('validationIssue') : true,
        val => (val) ? !isNaN(this.$formatter.replaceCommaWithDot(val)) || this.$t('validationIssue') : true,
        val => (this.$formatter.replaceCommaWithDot(val) > 0) || this.$t('validationIssue')
      ]
    },
    $_zeroSelectValidation () {
      return [
        val => val !== '' || this.$t('validationIssue'),
        val => val !== null || this.$t('validationIssue'),
        val => val !== undefined || this.$t('validationIssue')
      ]
    },
    quotePaymentTerms () {
      return [
        { name: `0 ${this.$t('quoteDays')}`, id: 0 },
        { name: `7 ${this.$t('quoteDays')}`, id: 7 },
        { name: `10 ${this.$t('quoteDays')}`, id: 10 },
        { name: `14 ${this.$t('quoteDays')}`, id: 14 },
        { name: `15 ${this.$t('quoteDays')}`, id: 15 },
        { name: `20 ${this.$t('quoteDays')}`, id: 20 },
        { name: `25 ${this.$t('quoteDays')}`, id: 25 },
        { name: `30 ${this.$t('quoteDays')}`, id: 30 },
        { name: `40 ${this.$t('quoteDays')}`, id: 40 },
        { name: `45 ${this.$t('quoteDays')}`, id: 45 },
        { name: `60 ${this.$t('quoteDays')}`, id: 60 }
      ]
    },
    quotePaymentTypes () {
      return [{ id: 1, name: 'Standard' }]
    },
    quoteStatus () {
      return [
        { id: 'draft', name: this.$t('quoteDraft') },
        { id: 'completed', name: this.$t('quoteCompleted') }
      ]
    },
    filterItemsDefault () {
      return [{
        text: this.$t('is'),
        value: 'is'
      }, {
        text: this.$t('isNot'),
        value: 'isnot'
      }, {
        text: this.$t('contains'),
        value: 'contains'
      }, {
        text: this.$t('startsWith'),
        value: 'startswith'
      }, {
        text: this.$t('endsWith'),
        value: 'endswith'
      }]
    },
    filterItemsSelect () {
      return [{
        text: this.$t('is'),
        value: 'is'
      }, {
        text: this.$t('isNot'),
        value: 'isnot'
      }]
    },
    filterItemsNumber () {
      return [{
        text: this.$t('equalTo'),
        value: 'equalto'
      }, {
        text: this.$t('notequal'),
        value: 'notequalto'
      }, {
        text: this.$t('lessThan'),
        value: 'lessthan'
      }, {
        text: this.$t('lessThanEqualTo'),
        value: 'lessthanorequalto'
      }, {
        text: this.$t('greaterThan'),
        value: 'greaterthan'
      }, {
        text: this.$t('greaterThanEqualTo'),
        value: 'greaterthanorequalto'
      }]
    },
    filterItemsDate () {
      return [{
        text: this.$t('today'),
        value: 'today'
      }, {
        text: this.$t('thisWeek'),
        value: 'thisweek'
      }, {
        text: this.$t('lastWeek'),
        value: 'lastweek'
      }, {
        text: this.$t('thisMonth'),
        value: 'thismonth'
      }, {
        text: this.$t('lastMonth'),
        value: 'lastmonth'
      }, {
        text: this.$t('before'),
        value: 'before'
      }, {
        text: this.$t('after'),
        value: 'after'
      }, {
        text: this.$t('between'),
        value: 'between'
      }, {
        text: this.$t('withinPast'),
        value: 'withinpast'
      }, {
        text: this.$t('withinNext'),
        value: 'withinnext'
      }]
    },
    filterItemsTime () {
      return [{
        text: this.$t('is'),
        value: 'is'
      }, {
        text: this.$t('isNot'),
        value: 'isnot'
      }, {
        text: this.$t('between'),
        value: 'between'
      }, {
        text: this.$t('before'),
        value: 'before'
      }, {
        text: this.$t('after'),
        value: 'after'
      }]
    },
    listOfRouteActions () {
      return [{
        id: this.ACTION_APPROVE_OR_DISAPPROVE,
        text: this.$t('approveordispprove')
      }, {
        id: this.ACTION_REVIEW_AND_MAKE_CHANGES,
        text: this.$t('reviewAndMakeChanges')
      }, {
        id: this.ACTION_ACKNOWLEDGE,
        text: this.$t('acknowledge')
      }, {
        id: this.ACTION_INFORMATION,
        text: this.$t('information')
      }]
    },
    listOfEscalationItems () {
      return [{
        text: this.$t('cancelTask'),
        id: this.ESCALATION_ACTION_CANCEL_WORKFLOW
      }, {
        text: this.$t('sendReminderRecipients'),
        id: this.ESCALATION_ACTION_SEND_REMINDER
      }]
    },
    listOfNotifyActions () {
      return [{
        id: this.NOTIFY_ON_ESCALATION,
        name: this.$t('ofEscalation')
      }, {
        id: this.NOTIFY_ON_ANY_PROGRESS,
        name: this.$t('anyProgress')
      }, {
        id: this.NOTIFY_ON_COMPLETION,
        name: this.$t('taskCompletion')
      }]
    },
    listOfDocumentWorkflowStatus () {
      return [{
        id: this.STATUS_CREATED,
        name: this.$t('created')
      }, {
        id: this.STATUS_INPROGRESS,
        name: this.$t('inProgress')
      }, {
        id: this.STATUS_COMPLETED,
        name: this.$t('completed')
      }, {
        id: this.STATUS_CANCELLED,
        name: this.$t('cancelled')
      }, {
        id: this.STATUS_ONHOLD,
        name: this.$t('onHold')
      }]
    },
    listOfWorkflowStatus () {
      return [{
        id: this.ACTION_APPROVED,
        name: this.$t('approved'),
        color: 'success'
      }, {
        id: this.ACTION_DISAPPROVED,
        name: this.$t('disApproved'),
        color: 'error'
      }, {
        id: this.ACTION_REVIEWED,
        name: this.$t('reviewed'),
        color: 'success'
      }, {
        id: this.ACTION_REJECTED,
        name: this.$t('rejected'),
        color: 'error'
      }, {
        id: this.ACTION_ACKNOWLEDGED,
        name: this.$t('acknowledged'),
        color: 'success'
      }, {
        id: this.ACTION_GOTIT,
        name: this.$t('gotIt'),
        color: 'primary'
      }]
    },
    $_imageUrl () {
      return process.env.VUE_APP_IMAGE_URL || ''
    }
  },
  methods: {
    preventSpecialCharacters (e) {
      if (['(', ')', '[', ']', '\\', '*', '+', '?', '^', '&', '#', '$', '!'].includes(e.key)) e.preventDefault()
    },
    checkForDuplicateNames (name, moduleName) {
      const query = [{ $match: { $or: [{ 'Data.name': { $options: 'i', $regex: name } }] } }]
      this.$api.execute('post', `moduledata/${moduleName}/query`, query).then(response => {
        if (response.data.length > 0) {
          this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'alreadyExists' })
        }
      })
    },
    checkForDuplicateData (fieldName, value, moduleName, element, index, id) {
      this.$api.execute('get', `switchworkspaces/get_workspace/${this.userDetails._id}`).then(response => {
        const availabletenantsId = response.data.availabletenants
        this.tenantIds = availabletenantsId.flatMap(tenantObj =>
          tenantObj.tenants.map(tenant => tenant._id)
        )
        if (value && value !== 'undefined') {
          const query = [{ $match: { [`Data.${fieldName}`]: value } }]
          this.$api.execute('post', `moduledata/${moduleName}/query`, query).then(response => {
            if (response.data.length > 0) {
              const filteredObj = response.data.find(x => x._id === id && x.data[`${fieldName}`] === value)
              if (!filteredObj) {
                this.$root.$emit('snackbar', { snackbar: true, color: 'apiError', text: `${fieldName} of ${value} is already exists, please enter unique value.`, isException: true })
                console.log('elem', element)
                this.emptyFieldValue(fieldName, value, moduleName, element, index, id)
                if (element) this.checkForValidationOrFunction(element, name, index)
              }
            }
          })
          if (element) this.checkForValidationOrFunction(element, name, index)
        }
      }).catch(error => {
        console.error('Error fetching workspaces:', error)
      })
    },
    toBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    getMimeTypeIcons (fileType) {
      let iconObj = {}
      switch (fileType) {
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': iconObj = { icon: 'mdi-file-word', color: 'primary' }
          break
        case 'application/pdf': iconObj = { icon: 'mdi-file-pdf', color: 'red' }
          break
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': iconObj = { icon: 'mdi-file-excel', color: 'green' }
          break
        default: iconObj = { icon: 'mdi-file', color: 'primary' }
      }
      return iconObj
    },
    groupMultipleObjectEmailValue (array) {
      if (Array.isArray(array)) {
        const result = array.map(x => x.email).join(', ')
        return result
      }
    },
    // Decode the String
    decodeStringTobase64 (string) {
      if (string) {
        var decodedString = atob(string)
        return decodedString
      }
    },
    afterTenantCreationHandler (loginData) {
      this.$store.state.common.loadRouter = true
      window.localStorage.removeItem(process.env.VUE_APP_CUSTOMERSETUP)
      this.$store.dispatch('loginInitalSetup', loginData).then(() => {
        if (loginData.issharepointstorage) {
          this.$api.execute('get', 'sharepointsetting').then((response) => {
            this.afterTenantLoginActions(response.data)
          })
        } else this.afterTenantLoginActions()
      })
    },
    afterTenantLoginActions (sharepointData) {
      this.$root.$emit('setSignalrAndNotification')
      // this.$store.dispatch('fetchIntegrations') // fetchIntegrations is not defined in store actions, therefore commenting it #riyaz
      this.$store.dispatch('getUserGroups').then(() => {
        this.$store.dispatch('getModuleList').then(() => {
          this.$store.dispatch('getUsers').then(() => {
            if (sharepointData && sharepointData.siteid && sharepointData.list_id) {
              this.$store.dispatch('getSharepointFields')
            }
            this.$store.commit('stopRouteLoader')
          })
        })
      })
      this.$store.dispatch('systemNotification').then((response) => {
        this.$root.$emit('getSystemNotificationFromTenant', response)
      })
      this.getWorkspacesOfCurrentUser()
      this.$store.dispatch('getTimerSettings')
      this.$router.push('/dashboard')
    },
    showUsersSelect (setDisabledForActiveUser) { // for switch worksapce selectedUsers
      if (this.tenantObj.whoHasAccessType === 3) {
        this.showUsers = true
        this.getListOfMSUsers(setDisabledForActiveUser)
        this.tenantObj.msUsers = [this.customerDomainInfo.email]
      } else this.showUsers = false
    },
    getListOfMSUsers (setDisabledForActiveUser) {
      this.userLoading = true
      this.$api.execute('post', `initialsetup/get_domain_users?customerDomain=${this.customerDomainInfo.customerdomain}`).then(response => {
        this.usersList = response.data
        /* Here add active user disable part */
        if (setDisabledForActiveUser) {
          const userIndex = this.usersList.findIndex(x => x.email === this.customerDomainInfo.email)
          if (userIndex > -1) this.usersList[userIndex].disabled = true
        }
      }).finally(() => {
        this.userLoading = false
      })
    },
    updateAppUserCookie () {
      var date = new Date()
      date.setDate(date.getDate() + 4)
      this.$cookie.set(process.env.VUE_APP_USER, JSON.stringify(encrypt(this.$store.state.auth.userDetails)), { expires: date })
    },
    getWorkflowStatusName (status) {
      const resultFound = this.listOfDocumentWorkflowStatus.find(x => x.id === status)
      if (resultFound) return resultFound.name
      else return ''
    },
    getUsername (id) {
      if (id) {
        const userFound = this.getUsers.find(x => x._id === id)
        if (userFound) return userFound.name
        else return ''
      } else return ''
    },
    getGroupName (id) {
      if (id) {
        const groupFound = this.getUserGroups.find(x => x._id === id)
        if (groupFound) return groupFound.name
        else return ''
      }
    },
    navigateToOD (document) {
      window.open(document.path, '_blank')
    },
    // use await keyword before this function call (Ex: await this.$_pause(500))
    $_pause (waitingTime) {
      return new Promise((resolve) => setTimeout(resolve, waitingTime))
    },
    getWorkspacesOfCurrentUser () {
      this.$api.execute('get', `switchworkspaces/get_workspace/${this.userDetails._id}`).then(response => {
        this.$store.commit('setListValues', { type: 'listOfTenantsAndWorkspaces', data: response.data.availabletenants })
      })
    }
  },
  filters: {
    firstLetterCaptialize (string) {
      if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      } else return ''
    }
  }
}
